<template>
    <b-modal
        id="modal-seleccionar-video"
        ref="my-modal"
        title="Selecciona o carga"
        no-close-on-backdrop
        ok-title="Seleccionar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        size="xl"
        @cancel="cerrarModal"
        @close="cerrarModal"
        :ok-disabled="selected==''"
        @ok.prevent="validarSeleccionar"
    >
    <b-card>
      <Video @selectItem="selectItemMedia" :tipo="tipo" id="scrollImg" />
    </b-card>
  </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import {BButton, BModal, VBModal, BCard} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import Video from "@/views/aplicaciones/medios/videos/Video.vue";

export default {
  components: {
    BButton,
    BModal,
    BCard,
    Video,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
        selected:'',
        tipo: "VIDEO",
    }
  },
  created() {

  },
  watch: {

  },
  methods: {
    cerrarModal(){
      this.selected='';
    },
    selectItemMedia(item){
      this.selected=item;
    },
    validarSeleccionar(){
      this.$nextTick(() => {
          this.$refs['my-modal'].toggle('#toggle-btn')
      })
      this.$emit('enviarVideo',this.selected);
    }
    
  },
}
</script>
<style lang="scss">
#scrollImg{
  .scroll-area1 {
    height: calc(var(--vh, 1vh) * 100 - 19.5rem) !important;
    overflow: hidden;
  }
  .modal-body {
      padding: 0rem !important;
  }
}
#modal-seleccionar-video{
  .modal-body {
      padding: 0rem !important;
  }
  .card{
    margin-bottom: 0rem !important;
  }
  .card-body {
      padding: 1rem;
  }
  #card-media{
    margin-bottom: 2rem !important;
  }
}
</style>
