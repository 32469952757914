<template>
    <div>
        <b-card :title="title">
                <b-row>
                    <b-col cols="12"
                    >
                        <b-button
                            v-b-modal.modal-seleccionar-video
                            variant="primary"
                        >
                            <span class="text-nowrap">Subir Video</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>
        
            <h6 class="my-2 text-muted">
                Listado de videos de la playlist
            </h6>
            <b-row style="margin-right:0rem; margin-left:0rem">
                <b-col
                    v-for="(item, index) in items" :key="index"
                    sm="6"
                    md="3"
                    lg="3"
                    cols="12"
                >
                    <b-card
                        :img-src="item.thumbnail"
                        img-top
                        img-alt="card img"
                        :header="item.title"
                        class=""
                    >
                    <b-card-text class="text-truncate">
                        {{item.description}} ..
                    </b-card-text>
                    <b-card-text class="d-flex justify-content-between align-items-center">
                        <small class="text-muted">{{item.published_at}} </small>
                        <div class="text-nowrap" v-if="item.opcion_id==3">
                            <b-button
                                v-b-modal.modal-video @click="abriComponente(item)" 
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                v-b-tooltip.hover.bottom="'Editar'"
                                class="btn-icon mr-1"
                                size="sm"
                            >
                                <feather-icon icon="EditIcon" />
                            </b-button>

                            <b-button
                                @click="confirmar_eliminar(item.id)"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-danger"
                                v-b-tooltip.hover.bottom="'Eliminar'"
                                class="btn-icon"
                                size="sm"
                            >
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </div>
                    </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        
        <VideoComponent @enviarVideo="recibirVideo"></VideoComponent>

        <EditarVideoExterior
        :accion="accionVideo" 
        :item="itemVideo" 
        @cerrarComponente="cerrarComponente" 
        @actualizarLista="actualizarLista" >
        </EditarVideoExterior>
    </div>
</template>
<script>
import axiosIns from '@/libs/axios'
import {BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BMedia, BMediaAside, BMediaBody, BTable, BInputGroup, BPagination, BOverlay, BInputGroupPrepend, BInputGroupAppend} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VideoComponent from '@/componentes/VideoComponent.vue';
import { computed } from '@vue/composition-api';
import EditarVideoExterior from '@/views/videos/exterior/EditarVideoExterior.vue';
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BImg, 
    BDropdown, BDropdownItem,
    BListGroup, BListGroupItem,
    BMedia, BMediaAside, BMediaBody,
    BTable,
    BInputGroup,
    BPagination,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BInputGroupPrepend, BInputGroupAppend,

    VideoComponent,
    EditarVideoExterior
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data () {
      return {
        errores:[],
        items: [],
        playlists:[],
        itemPlaylist:[],
        loading: false,
        id:null,
        opcion_id:2,
        videoId:'',
        title:'',
        thumbnail:'',
        itemVideo:[],
        accionVideo:'',
        playlistId:'',
        submitted: false,
      }
  },
  computed:{

  },
  created(){
    if (this.$route.params.id) {
      this.listarVideos(this.$route.params.id);
    }       
  },
  watch: {

  },
  methods: {
    async listarVideos(id) {
        this.id = id;
        var url= '/admin/playlist/'+ this.id;
        axiosIns.get(url)
        .then(res => {
            this.title = 'PlayList '+res.data.title;
            this.playlistId=res.data.playlistId;
            this.items = res.data.play_list_item;
        })
        .catch(err =>{
            this.$router.push({name: 'video'});
            console.log(err);
        });
    },

    registrar(){
        axiosIns.post('/admin/videos',{
            'opcion_id':2,
            'videoId':this.videoId,
            'playlistId':this.playlistId,
            'title':this.title,
            'thumbnail':this.thumbnail,
        })
        .then(res => {
            this.listarVideos(this.id);
            this.errores=[];
            this.submitted = false;
        })
        .catch(err =>{
            this.$bvToast.toast('Video ya fue seleccionado!', {
                title: 'Advertencia!',
                variant:'danger',
                solid: false,
            })
            this.submitted = false;
            this.errores=err.response.data.errors;
        });
    },

    confirmar_eliminar(id) {
        this.$swal({
        title: "Advertencia",
        text: "¿Estás seguro de que deseas remover el Video de la lista?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        }).then((result) => {
        if (result.value) {
            this.eliminar(id);
        }
        });
    },
    eliminar(id) {
        axiosIns.delete('/admin/videos/'+id)
        .then(res => {
            this.$bvToast.toast('Exito', {
            title: 'Se removio correctamente',
            variant:'primary',
            solid: false,
            })
            this.listarVideos(this.id);
        }) 
        .catch(err =>{
            console.log(err);
        });
    },

    //COMPONENTE EDITAR
    //video titulo
    actualizarLista(item){
        this.listarVideos(this.id);
        this.accionVideo='';
        this.itemVideo=[];
    },
    abriComponente(item){
        this.accionVideo="actualizar";
        this.itemVideo=item;
    },
    cerrarComponente(){
        this.accionVideo='';
        this.itemVideo=[];
    },

    //RECIBIR AUDIO DEL COMPONENTE
    recibirVideo(video){
        if (video.miniatura.length) {
            this.videoId=video.url;
            this.thumbnail=video.miniatura;
        }else{
           this.videoId=video.url;
            this.thumbnail='';
        }
        if (!this.submitted) {
            this.submitted = true;
            this.registrar();
        }
    },
  },
}
</script>
<style lang="scss">

</style>