<template>
    <b-modal
        id="modal-video"
        ref="my-modal"
        :title="tituloModal"
        no-close-on-backdrop
        ok-title="Guardar"
        cancel-variant="outline-secondary"
        cancel-title="Cerrar"
        @cancel="cerrarModal"
        @close="cerrarModal"
        @ok.prevent="validar"
    >

     <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <!-- titulo -->
                <b-col cols="12">
                    <b-form-group
                        label="Titulo"
                        label-for="Titulo"
                    >
                        <validation-provider
                        #default="{ errors }"
                        name="Titulo"
                        rules="required|min:4"
                        >
                        <b-form-input
                            id="nombre"
                            v-model="title"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-for="error in errores.title" :key="error" class="text-danger">{{ error }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- fecha -->
                <b-col cols="12">
                    <b-form-group
                        label="Fecha publicacion"
                        label-for="Fecha publicacion"
                    >
                        <flat-pickr
                            v-model="published_at"
                            class="form-control"
                            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                        />
                        <small v-for="error in errores.published_at" :key="error" class="text-danger">{{ error }}</small>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>

    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BForm, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BForm,
        BRow,
        BCol,
        BFormSelect,
        flatPickr,
    },
    props:[
        'accion','item'
    ],
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    watch: {
        accion: function () {
            this.errores=[]; 
            if (this.accion) {
                this.abrir(this.accion,this.item);
            }
        },
        title: function () { 
            this.errores.title=[];
        },
        published_at: function () { 
            this.errores.published_at=[];
        },
    },
    data () {
        return {
            id:null,
            title:'',
            published_at:'',
            required,
            submitted: false,
            errores:[],
            tituloModal:'',
        }
    },
    methods:{
        validar() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (!this.submitted) {
                        this.submitted = true;
                        if(this.tituloModal=='Nuevo'){
                            this.registrar();
                        }else{
                            this.actualizar();
                        }
                    }
                }
            })
        },
        abrir(accion,data=[]){
            switch(accion){
                case "registrar":
                {
                    this.tituloModal='Nuevo';
                    this.title='';
                    break;
                }
                case "actualizar":
                {
                    this.tituloModal='Video: Editar titulo';
                    this.id=data['id'];
                    this.title=data['title'];
                    this.published_at=data['published_at'];
                    break;	
                }
            }
        },
        cerrarModal(){
            this.$emit('cerrarComponente');
        },
        actualizarLista(item){
            this.$emit('actualizarLista',item);
            this.submitted = false;
        },
        registrar(){
            axiosIns.post('/admin/videos',{
                'title':this.title,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista(res.data);
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        },
        actualizar(){
            axiosIns.put('/admin/videos/'+this.id,{
                'title':this.title,
                'published_at':this.published_at,
            })
            .then(res => {
                this.$nextTick(() => {
                    this.$refs['my-modal'].toggle('#toggle-btn')
                })
                this.actualizarLista();
                this.errores=[];
            })
            .catch(err =>{
                this.submitted = false;
                this.errores=err.response.data.errors;
            });
        }
    }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>